import React from 'react';
import { Popup } from 'react-leaflet';
import NoPhoto from '@bvt-features/pertamina/assets/nophoto.svg';
import './MarkerPopup.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const MarkerPopup = (props) => {
  const { lat, lng, properties } = props;

  if (lat && lng) {
    const commonPopupProps = {
      className: 'MarkerPopup__popup',
      closeButton: false,
      offset: { x: 0, y: -45 },
      position: { lat, lng },
    };

    const renderDetails = () => {
      if (!properties.type) {
        return (
          <>
            <div className='title'>SPBU {properties.name ?? 'Asset Name'}</div>
            <div>{lat}, {lng}</div>
            <div className='address'>{properties?.address?.toLowerCase() === 'none' ? '-' : properties?.address}</div>
          </>
        );
      }

      switch (properties.type) {
        case 'tenant':
          return (
            <>
              <div className='info'>Industry: <span>{properties?.industry_name}</span></div>
              <div className='info'>Group: <span>{properties?.group_name}</span></div>
              <div className='info'>Category: <span>{properties?.category_name}</span></div>
              <div className='info'>Brand: <span>{properties?.brand_name}</span></div>
            </>
          );
        case 'shophouse':
          return (
            <>
              <div className='info'>POI ID: <span>{properties?.id}</span></div>
              <div className='info'>Address: <span>{properties?.address}</span></div>
              <div className='info'>Building Area (m²): <span>{properties?.building_area_m2}</span></div>
              <div className='info'>Number of Floors: <span>{properties?.number_of_floors}</span></div>
              <div className='info'>Land Area (m²): <span>{properties?.land_area_m2}</span></div>
              <div className='info'>Rental Price per Month (Rp/m²): <span>{properties?.rental_price_per_month_m2}</span></div>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <Popup {...commonPopupProps}>
        <div className={`MarkerPopup__popup-wrap${properties.type ? '-dashboard' : ''}`}>
          <div className='img-wrapper'>
            {!properties.type && (
              <LazyLoadImage
                className='img'
                effect='blur'
                placeholderSrc={NoPhoto}
                src={properties?.image || NoPhoto}
                wrapperProps={{
                  style: { width: '100%' },
                }}
              />
            )}
          </div>
          <div className='detail'>{renderDetails()}</div>
        </div>
      </Popup>
    );
  }

  return null;

};
