import React, { useEffect } from 'react';
import './PertaminaDownloadNotificationContainer.scss';
import { Progress } from 'antd';
import { usePertaminaAsset } from '@bvt-features/pertamina/hooks/usePertaminaAsset';

export const PertaminaDownloadNotificationContainer = () => {
  const asset = usePertaminaAsset();
  const { progress, loadedSize, totalSize, filename } =
    asset.state.data.list.download;
  const convertBit = (value) => {
    if (!value) {
      return '-';
    }
    let size = value / 1024;
    let type = 'KB';
    if (size > 1024) {
      size = size / 1024;
      type = 'MB';
    }
    return `${(size - size * 0.235).toFixed(2)}${type}`;
  };

  useEffect(() => {
    if (loadedSize === totalSize) {
      setTimeout(() => {
        asset.setStartDownload(false);
        asset.resetDownload();
      }, 5000);
    }
  }, [progress]);

  return (
    <>
      {asset.state.data.list.download.start && (
        <div className='feature-pertamina PertaminaDownloadNotification'>
          <div className='container'>
            <div className='header'>Download</div>
            <div className='content'>
              <div className='item'>
                <span className='name'>{filename || '-'}</span>
                <span className='persen'>{progress ?? '-'}%</span>
              </div>
              <Progress
                percent={totalSize ? progress : 100}
                showInfo={false}
                status={totalSize ? 'normal' : 'active'}
                strokeColor={'#05A961'}
              />
              <span className='size'>
                {convertBit(loadedSize)} of {convertBit(totalSize)}
              </span>
              <span className='information'>Please do not close this page</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
