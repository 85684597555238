import { useDispatch, useSelector } from 'react-redux';
import { MAIN_MAP_ACTION } from '../../store/mainmap.slice';

/**
 * @author Randa <m.randa@bvarta.com>
 * @author Malik <malik.ibrahim@bvarta.com>
 * @description Hook for use main map, and component inside mainmap
 */
export const useMainMap = () => {
  const state = useSelector(
    /**
     *  @param {ReturnType<typeof import('../../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.MAINMAP.MAINMAP
  );

  const dispatch = useDispatch();

  /**
   * @param {'LAYERING_VISIBLITY'| 'BASEMAP' | 'DATEX' | 'DETAIL_ASMAN_PERTAMINA' | undefined} param
   */
  const setActiveMenu = (param) => {
    dispatch(MAIN_MAP_ACTION.setActiveMenu(param));
  };

  /**
   * @param {"defaultSetting" | "nightMapSetting" | "lightMapSetting" | "streetMapSetting" | "satelitMapSetting"} param
   */
  const setBaseMap = (param) => {
    dispatch(MAIN_MAP_ACTION.setActiveBaseMap(param));
  };

  const setActiveMenuWidth = (param) => {
    dispatch(MAIN_MAP_ACTION.setActiveMenuWidth(param));
  };

  /**
   *
   * @param {object} param
   * @param {string} param.key
   * @param {boolean} param.isActive
   * @param {number} param.opacity
   * @param {string} param.title
   * @param {array<string>} param.description
   */
  const registerLayer = (param) => {
    dispatch(MAIN_MAP_ACTION.registerLayer(param));
  };
  /**
   *
   * @param {object} param
   * @param {string} param.key
   * @param {boolean} param.isActive
   * @param {number} param.opacity
   * @param {string} param.title
   * @param {array<string>} param.description
   */
  const updateLayer = (param) => {
    dispatch(MAIN_MAP_ACTION.updateLayer(param));
  };

  /**
   *
   * @param {string} param
   */
  const removeLayer = (param) => {
    dispatch(MAIN_MAP_ACTION.removeLayer(param));
  };

  const clearLayer = () => {
    dispatch(MAIN_MAP_ACTION.clearLayer());
  };

  return {
    state,
    setActiveMenu,
    setBaseMap,
    registerLayer,
    removeLayer,
    updateLayer,
    clearLayer,
    setActiveMenuWidth,
  };
};
