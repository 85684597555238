import React from 'react';
import './Legend.scss';

// getIcon('poi', category?.icon)

const ItemLegend = (props) => {
  return (
    <div className='item'>
      <img alt='pertamina' className='icon' src={props.iconUrl} />
      <div className='name'>{props.name}</div>
    </div>
  );
};

export const PertaminaLegend = ({ items, ...restProps }) => {
  return (
    <div className='PertaminaLegend fixed' {...restProps} >
      <div className='PertaminaLegend wrapper'>
        <div className='title'>Legend</div>
        <div className='content'>
          {items?.map((item) => (
            <ItemLegend
              iconUrl={item.icon}
              key={item.name}
              name={item.name}
            />
          ))
          }
        </div>
      </div>
    </div>
  );
};
