import { asyncActionCheckMarkerIsValid } from '@bvt-features/pertamina/store/droppable-marker/droppable_marker.asyncAction';
import { droppableMarkerActions } from '@bvt-features/pertamina/store/droppable-marker/droppable_marker.slice';
import { useDispatch, useSelector } from 'react-redux';

export const usePertaminaDroppableMarker = () => {
  const state = useSelector(
    /**
     * @param {ReturnType<typeof import('../../../../redux/rootStore').rootStore.getState>} state
     */
    (state) => state.features.PERTAMINA.droppableMarker
  );

  const dispatch = useDispatch();

  /**
   * @description For marker list and history
   * @typedef TMarkerObject
   * @type {object}
   * @property {number} id
   * @property {string} siteName
   * @property {string} address
   * @property {string} IconColor
   * @property {string} icon_type
   * @property {Array<number|string>} coordinate
   * @property {number} id_provinsi
   * @property {string} nama_provinsi
   * @property {number} id_kota
   * @property {string} nama_kota
   * @property {number} id_kecamatan
   * @property {string} nama_kecamatan
   * @property {number} id_desa
   * @property {string} nama_desa
   * @property {boolean} isChecked
   * @property {number} latitude
   * @property {number} longitude
   * @property {string} id_group
   * @property {Array<TMarkerHistoryObject>} history
   * @property {Array<string>} MarkerGroups
   */

  /**
   * @param {object} param
   * @param {TMarkerObject} param.marker
   */
  const addNewMarkerToList = (param) => {
    dispatch(droppableMarkerActions.addNewMarkerToList(param));
  };

  /**
   * @param {object} param
   * @param {import('../store/droppable_marker.slice').TMarkerHistoryObject} param.history
   */
  const addNewHistoryToList = (param) => {
    dispatch(droppableMarkerActions.addNewHistoryToList(param));
  };

  const clearHistoryList = (param) => {
    dispatch(droppableMarkerActions.clearHistoryLisst(param));
  };

  /**
   * @param {object} param
   * @param {number} param.id
   */
  const deleteMarkerById = (param) => {
    dispatch(droppableMarkerActions.deleteMarkerById(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {number} param.id
   * @param {TMarkerObject} param.data
   */
  const saveMarkerOrEditById = (param) => {
    dispatch(droppableMarkerActions.saveMarkerOrEditById(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeDragStatus = (param) => {
    dispatch(droppableMarkerActions.changeDragStatus(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeMarkerColor = (param) => {
    dispatch(droppableMarkerActions.changeMarkerColor(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const changeMarkerIcon = (param) => {
    dispatch(droppableMarkerActions.changeMarkerIcon(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const setHistoryMarkerActiveIndex = (param) => {
    dispatch(droppableMarkerActions.setHistoryMarkerActiveIndex(param));
  };

  /**
   * @description
   * @param {object} param
   * @param {boolean} param.payload
   */
  const setMarkerActiveIndex = (param) => {
    dispatch(droppableMarkerActions.setMarkerActiveIndex(param));
  };

  // CEK VALID AREA
  /**
   * @description
   * @param {object} param
   * @param {number} param.longitude
   * @param {TMarkerObject} param.latitude
   * @param {TMarkerObject} param.id_source
   */
  const checkIfAreaIsValid = (param) => {
    dispatch(asyncActionCheckMarkerIsValid(param || {}));
  };

  const resetState = () => {
    dispatch(droppableMarkerActions.resetState());
  };

  const resetMarkerColor = () => {
    dispatch(droppableMarkerActions.resetMarkerColor());
  };

  const resetMarkerIcon = () => {
    dispatch(droppableMarkerActions.resetMarkerIcon());
  };

  const filterMarkerList = (param) => {
    dispatch(droppableMarkerActions.setFilterMarkerList(param));
  };

  const filterMarkerByArea = (param) => {
    dispatch(droppableMarkerActions.setFilterByArea(param));
  };

  /**
   * @param {{ x: number, y: number }} payload
   */
  const setPoint = (payload) => {
    dispatch(droppableMarkerActions.setPoint(payload));
  };

  /**
   * @param {object} payload
   * @param {number[]} payload.ids
   */
  const setActiveMarkers = (payload) => {
    dispatch(droppableMarkerActions.setActiveMarkers(payload));
  };
  /**
   * @param {object} payload
   * @param {number[]} payload.ids
   */
  const setActiveMarkersRight = (payload) => {
    dispatch(droppableMarkerActions.setActiveMarkersRight(payload));
  };

  /**
   * @param {[string, string]} coordinate
   */
  const setCoordinate = (coordinate) => {
    dispatch(droppableMarkerActions.setCoordinate(coordinate));
  };

  /**
   * @param {TMarkerObject[]} payload
   */
  const setMarkerList = (payload) => {
    dispatch(droppableMarkerActions.setMarkerList(payload));
  };

  return {
    state,
    addNewMarkerToList,
    addNewHistoryToList,
    clearHistoryList,
    deleteMarkerById,
    saveMarkerOrEditById,
    checkIfAreaIsValid,
    changeDragStatus,
    changeMarkerColor,
    changeMarkerIcon,
    setHistoryMarkerActiveIndex,
    setMarkerActiveIndex,
    resetState,
    resetMarkerColor,
    resetMarkerIcon,
    filterMarkerList,
    filterMarkerByArea,
    setPoint,
    setActiveMarkers,
    setActiveMarkersRight,
    setCoordinate,
    setMarkerList,
  };
};
