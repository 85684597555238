import React, { lazy, Suspense } from 'react';

const Suspensed = (Element) =>
  function suspense(props) {
    return (
      <Suspense fallback={<div />}>
        <Element {...props} />
      </Suspense>
    );
  };

export default {
  ForgotPassword: Suspensed(lazy(() => import('./ForgotPassword'))),
  Login: Suspensed(lazy(() => import('./Login'))),
  MainHistory: Suspensed(lazy(() => import('./MainHistory'))),
  MainHome: Suspensed(lazy(() => import('./MainHome'))),
  MainNew: Suspensed(lazy(() => import('./MainNew'))),
  MainNewDetail: Suspensed(lazy(() => import('./MainNewDetail'))),
  MainOpen: Suspensed(lazy(() => import('./MainOpen'))),
  MainSetting: Suspensed(lazy(() => import('./MainSetting'))),
  ResetPassword: Suspensed(lazy(() => import('./ResetPassword'))),
  GeneralSetup: Suspensed(lazy(() => import('./GeneralSetup'))),
  GeneralSetupPOI: Suspensed(lazy(() => import('./GeneralSetupPOI'))),
  GeneralSetupPOIEditForm: Suspensed(
    lazy(() => import('./GeneralSetupPOIEditForm'))
  ),
  GeneralSetupPOIForm: Suspensed(lazy(() => import('./GeneralSetupPOIForm'))),
  GensetParameter: Suspensed(lazy(() => import('./GensetParameter'))),
  GensetParameterForm: Suspensed(lazy(() => import('./GensetParameterForm'))),
  GensetParameterEdit: Suspensed(lazy(() => import('./GensetParameterEdit'))),
  ErrorFourOOne: Suspensed(lazy(() => import('./ErrorFourOOne'))),
  ErrorFourOThree: Suspensed(lazy(() => import('./ErrorFourOThree'))),
  ErrorFourOFour: Suspensed(lazy(() => import('./ErrorFourOFour'))),
  ErrorFourOneNine: Suspensed(lazy(() => import('./ErrorFourOneNine'))),
  ErrorFiveHundred: Suspensed(lazy(() => import('./ErrorFiveHundred'))),
  ErrorFiveOThree: Suspensed(lazy(() => import('./ErrorFiveOThree'))),
  CreateEditGridAnalysis: Suspensed(
    lazy(() => import('./CreateEditGridAnalysis'))
  ),
  CreateEditGridAnalysisLawu: Suspensed(
    lazy(() => import('./CreateEditGridAnalysisLawu'))
  ),
  PageDashboardMap: Suspensed(lazy(() => import('./PageDashboardMap'))),
  CreateEditSiteProfiling: Suspensed(
    lazy(() => import('./CreateEditSiteProfiling'))
  ),
  CreateEditSiteProfilingMandala: Suspensed(
    lazy(() => import('./CreateEditSiteProfilingMandala'))
  ),
  Mapana: Suspensed(lazy(() => import('./Mapana'))),
  DashboardResult: Suspensed(lazy(() => import('./Dashboard'))),
  PreviewPdf: Suspensed(lazy(() => import('./PreviewPdf'))),
  SubModuleAsman: Suspensed(lazy(() => import('./SubModule'))),
  Pertamina: Suspensed(lazy(() => import('./Pertamina'))),
  PertaminaDashboard: Suspensed(lazy(() => import('./PertaminaDashboard'))),
  PertaminaPreviewPDF: Suspensed(lazy(() => import('./PertaminaPreviewPDF'))),
};
