const columns = [
  {
    title: 'Pertamina Category',
    dataIndex: 'category',
    key: 'category',
    width: 95,
  },
  {
    title: 'Pertamina Sub-Category',
    dataIndex: 'subCategory',
    key: 'subCategory',
    width: 95,
  },
  {
    title: 'BVT Category',
    dataIndex: 'bvtCategory',
    key: 'bvtCategory',
    width: 95,
  },
  {
    title: 'Actual',
    dataIndex: 'actual',
    key: 'actual',
    width: 80,
  },
  {
    title: 'Expected',
    dataIndex: 'expected',
    key: 'expected',
    width: 80,
  },
  {
    title: 'Gap %',
    dataIndex: 'gap',
    key: 'gap',
    width: 80,
  },
];

export default columns;
