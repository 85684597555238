import { createSlice } from '@reduxjs/toolkit';
import {
  createUpdateAsset,
  doDeleteAsset,
  doDownloadAssetList,
  getDetailAsset,
  getListAsset,
} from './asset.asyncAction';

const initialState = {
  status_LIST_ASSET: 'IDLE',
  status_DASHBOARD_RESULT: 'IDLE',
  status_DETAIL_ASSET: 'IDLE',
  status_DOWNLOAD: 'IDLE',
  status_ADD_EDIT: 'IDLE',
  status_DELETE_ASSET: 'IDLE',
  description: '',
  message: '',
  data: {
    list: {
      data: [],
      selectedID: '',
      selectedImg: '',
      coordinate: {
        lat: '',
        lng: '',
      },
      download: {
        url: '',
        filename: '',
        progress: null,
        totalSize: null,
        loadedSize: null,
        start: false,
      },
      edit: {}
    },
    detail: {
      data: {},
    },
    dashboard: {},
  },
};
export const assetSlice = createSlice({
  name: 'LI/PERTAMINA/ASSET',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    resetDashboard: (state) => {
      state.status_DASHBOARD_RESULT = 'IDLE';
      state.data.dashboard = {};
    },
    resetStatus: (state, action) => {
      const { payload } = action;
      state.message = '';
      state.description = '';

      switch (payload.type) {
        case 'status_DASHBOARD_RESULT':
          state.status_DASHBOARD_RESULT = 'IDLE';
          break;
        case 'status_LIST_ASSET':
          state.status_LIST_ASSET = 'IDLE';
          break;
        case 'status_ADD_EDIT':
          state.status_ADD_EDIT = 'IDLE';
          break;
        default:
          state.status_DASHBOARD_RESULT = 'IDLE';
          state.status_LIST_ASSET = 'IDLE';
          break;
      }
      return state;
    },
    setSelectedID: (state, action) => {
      state.data.list.selectedID = action.payload.id;
    },
    setSelectedImg: (state, action) => {
      state.data.list.selectedImg = action.payload.img;
    },
    setCoordinate: (state, action) => {
      state.data.list.coordinate = action.payload.coordinate;
    },
    setDownloadProgress: (state, { payload }) => {
      state.data.list.download.progress = payload;
    },
    setDownloadTotalSize: (state, { payload }) => {
      state.data.list.download.totalSize = payload;
    },
    setDownloadLoadedSize: (state, { payload }) => {
      state.data.list.download.loadedSize = payload;
    },
    setStartDownload: (state, { payload }) => {
      state.data.list.download.start = payload;
    },
    setEditData: (state, { payload }) => {
      state.data.list.edit = payload;
    },
    resetDownload: (state) => {
      state.data.list.download = initialState.data.list.download;
    },
  },
  extraReducers: (builder) =>
    builder
      // LIST
      .addCase(getListAsset.pending, (state) => {
        state.status_LIST_ASSET = 'LOADING';
      })
      .addCase(getListAsset.fulfilled, (state, action) => {
        state.status_LIST_ASSET = 'SUCCESS';
        state.data.list.data = action.payload.data;
      })
      .addCase(getListAsset.rejected, (state, action) => {
        state.status_LIST_ASSET = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // Detail
      .addCase(getDetailAsset.pending, (state) => {
        state.status_DETAIL_ASSET = 'LOADING';
      })
      .addCase(getDetailAsset.fulfilled, (state, action) => {
        state.status_DETAIL_ASSET = 'SUCCESS';
        state.data.detail.data = action.payload.data;
      })
      .addCase(getDetailAsset.rejected, (state, action) => {
        state.status_DETAIL_ASSET = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // Download
      .addCase(doDownloadAssetList.pending, (state) => {
        state.status_DOWNLOAD = 'LOADING';
      })
      .addCase(doDownloadAssetList.fulfilled, (state, { payload }) => {
        state.data.list.download.filename = payload.filename;
        state.data.list.download.url = payload.url;
        state.status_DOWNLOAD = 'SUCCESS';
      })
      .addCase(doDownloadAssetList.rejected, (state, action) => {
        state.status_DOWNLOAD = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      .addCase(doDeleteAsset.pending, (state) => {
        state.status_DELETE_ASSET = 'LOADING';
      })
      .addCase(doDeleteAsset.fulfilled, (state) => {
        state.status_DELETE_ASSET = 'SUCCESS';
      })
      .addCase(doDeleteAsset.rejected, (state, action) => {
        state.status_DELETE_ASSET = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      })
      // Add Edit
      .addCase(createUpdateAsset.pending, (state) => {
        state.status_ADD_EDIT = 'LOADING';
      })
      .addCase(createUpdateAsset.fulfilled, (state) => {
        state.status_ADD_EDIT = 'SUCCESS';
      })
      .addCase(createUpdateAsset.rejected, (state, action) => {
        state.status_ADD_EDIT = 'FAILED';
        state.description = action.error.message;
        state.message = action.error.message;
      }),
});

export const { reducer: assetReducer, actions: assetActions } = assetSlice;
