import React from 'react';
import DynamicIcon from '@bvt-icons';
import './LegendContainer.scss';
import { useMap } from 'react-leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { useLegend } from '@bvt-features/mainmap/legend/hooks/useLegend';
import { SymbolType } from '../../component/SymbolType';
import { Collapse, Spin } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { generateUUID } from '@bvt-utils';

/**
 * @copyright PT Bhumi Varta Technology
 * @author Renta<renta.yustika@bvarta.com>
 */

export const LegendContainer = () => {
  const LEGEND = useLegend();
  const initialMap = useMap();
  const handleOpenLegend = () => {
    LEGEND.toggleOpenLegend(!LEGEND.state?.isOpen);
  };

  const mapp = useMainMap();

  const emptyContent = <li className='bvt-map__legend__content__li center'>NO CONTENT</li>;
  const renderIcon = ({ isActive }) => (
    <CaretRightOutlined rotate={isActive ? 90 : 0} />
  );

  /**
   * @description For legend list
   * @typedef ILegendParent
   * @type {object}
   * @property {string|number} key
   * @property {string} title
   * @property {{[x:string]:Array<ILegendItem>}} items
   */

  /**
   * @description For legend list item
   * @typedef ILegendItem
   * @type {object}
   * @property {string|number} key
   * @property {'CIRCLE'|'SQUARE'|'POLY'|'ICON'} type
   * @property {React.ReactElement} icon
   * @property {string} title
   * @property {string} color
   * @property {string} group
   */

  /**
   * @param {object} props
   * @param {Array<ILegendParent>} props.data
   */
  const FillContent = (props) => {
    const { data } = props;
    const sortedKeys = ['POI', 'TELCO', 'THEMATIC', 'GRIANA', 'SIPRO'];
    const sortedData = _.fromPairs(
      sortedKeys
        .filter(key => Object.hasOwn(data, key)) // Only include keys that exist in the object
        .map(key => [key, data[key]])
    );
    return Object.values(sortedData)?.map((parent) => {
      // Sort the entries by key (alphabetical order)
      const sortedEntries = Object.entries(parent.items).sort(([keyA], [keyB]) => {
        return keyA.localeCompare(keyB);
      });
      // Convert sorted entries back to an object if needed
      const sortedObj = Object.fromEntries(sortedEntries);

      const remappedData = _.reduce(sortedObj, (result, items, group) => {
        result[group] = {
          groupOrder: _.get(items, '[0].groupOrder', null),
          id: _.get(items, '[0].id', null),
          items,
          group,
        };
        return result;
      }, {});

      return (
        <Collapse
          bordered={true}
          className='legend-collapse'
          defaultActiveKey={['1']}
          expandIcon={renderIcon}
          key={parent.key}
        >
          <Collapse.Panel className='legend-collapse-header' header={parent.title}>
            {_.orderBy(Object.values(remappedData), ['groupOrder', 'id'], ['asc', 'desc'])?.map((object, ) => (
              <div className='legend-collapse-content' key={generateUUID()}>
                {object.group !== 'undefined' && <div className='legend-collapse-sub-title'>{object.group}</div>}
                <div className='legend-collapse-content-item'>
                  {_.orderBy(object.items, ['order', 'title'], ['asc', 'asc']).map((item) => (
                    <div className='bvt-map__legend__content__li' key={generateUUID()}>
                      <div className='bvt-map__legend__content__li--icon'>
                        <SymbolType
                          color={item?.color}
                          icon={item?.icon}
                          key={item?.key}
                          type={item?.type}
                        />
                      </div>
                      <div className='bvt-map__legend__content__li--desc'>
                        {item?.title || '-'}
                      </div>
                    </div>
                  ))
                  }
                </div>
              </div>
            ))}
          </Collapse.Panel>
        </Collapse>
      );
    });
  };

  if (!LEGEND.state.isShow) {
    return null;
  }

  return (
    <div
      className={`bvt-map__legend`}
      onMouseEnter={() => initialMap.scrollWheelZoom.disable()}
      onMouseLeave={() => initialMap.scrollWheelZoom.enable()}
      style={{
        right: mapp.state.activeMenu
          ? mapp.state.activeMenuWidth + 8 + 'px'
          : '0.5rem',
      }}
    >
      <div className='bvt-map__legend__wrapper'>
        <div
          className={`bvt-map__legend__content ${
            LEGEND.state?.isOpen ? 'show' : ''
          } scroll`}
        >
          {LEGEND.state?.isLoading ? (
            <Spin className='bvt-map__legend__content__loader' />
          ) : (
            <ul className='bvt-map__legend__content__ul'>
              {Object.values(LEGEND.state?.grouppedData)?.length === 0 ? (
                emptyContent
              ) : (
                <FillContent
                  data={LEGEND.state?.grouppedData}
                />
              )}
            </ul>
          )}
        </div>
        <div className='bvt-map__legend__action'>
          <button
            className={`bvt-map__legend__button ${
              LEGEND.state?.isOpen ? 'show' : ''
            }`}
            onClick={handleOpenLegend}
          >
            <div className='bvt-map__legend__button__container'>
              {LEGEND.state?.isOpen ? 'Hide Legend' : 'Show Legend'}
              <DynamicIcon
                className={`bvt-map__legend__button--arrow ${
                  LEGEND.state?.isOpen ? 'show' : ''
                }`}
                height='0.588rem'
                icon='arrow'
                width='0.588rem'
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
