import { LEGACY_AUTH_CONSTANT } from '@bvt-features/auth/redux';
import { createSlice } from '@reduxjs/toolkit';
import IMAGE_MAP_LIGHT from '@bvt-assets/images/lightMap.png';
import IMAGE_MAP_DARK from '@bvt-assets/images/darkMap.png';
import IMAGE_MAP_BRIGHT from '@bvt-assets/images/brightMap.png';
import IMAGE_MAP_STREETS from '@bvt-assets/images/streetsMap.png';
import IMAGE_MAP_SATELLITE from '@bvt-assets/images/satelitteHybridMap.png';
import _ from 'lodash';

/**
 * @typedef initialStateModel
 * @type {object}
 * @property {Array<{key:string,value:string,title:string,image:string}>} baseMapList
 * @property {Array<{key:string,opacity:number,isActive:boolean,title:string,description:array<string>}>} layerVisibilityList
 * @property {'defaultSetting' | 'nightMapSetting' | 'lightMapSetting' | 'streetMapSetting' | 'satelitMapSetting'} selectedBaseMap
 * @property {'LAYERING_VISIBLITY'| 'BASEMAP' | 'DATEX' | undefined} activeMenu
 * @property {number} activeMenuWidth
 */

/**
 * @type {initialStateModel} initialState
 */
const initialState = {
  activeMenu: null,
  activeMenuWidth: 372,
  baseMapList: [
    {
      key: 'defaultSetting',
      value:
        'https://basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
      title: 'Light (default)',
      image: IMAGE_MAP_LIGHT,
    },
    {
      key: 'nightMapSetting',
      value:
        'https://basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
      title: 'Dark',
      image: IMAGE_MAP_DARK,
    },
    {
      key: 'lightMapSetting',
      value:
        'https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png',
      title: 'Bright',
      image: IMAGE_MAP_BRIGHT,
    },
    {
      key: 'streetMapSetting',
      value:
        'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      title: 'Streets',
      image: IMAGE_MAP_STREETS,
    },
    {
      key: 'satelitMapSetting',
      value: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}&apistyle=s.t:2|s.e:l|p.v:off,s.t:4|s.e:l|p.v:off',
      title: 'Satellite Hybrid',
      image: IMAGE_MAP_SATELLITE,
    },
  ],
  layerVisibilityList: [],
  selectedBaseMap: 'defaultSetting',
};

export const mainMapSlice = createSlice({
  name: 'LI/mapp/main-map',
  initialState,
  reducers: {
    setActiveMenu(state, { payload }) {
      if (payload) {
        state.activeMenu = payload;
      } else {
        state.activeMenu = null;
      }
      if(state.activeMenu === 'DETAIL_ASMAN_PERTAMINA'){
        state.activeMenuWidth = 500;
      } else {
        state.activeMenuWidth = 372;
      }
    },
    setActiveBaseMap(state, { payload }) {
      state.selectedBaseMap =
        _.find(state.baseMapList, (v) => v.key === payload)?.key ||
        'defaultSetting';
    },
    /**
     * @param {number} payload
     */
    setActiveMenuWidth(state, { payload }) {
      state.activeMenuWidth = payload;
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.key
     * @param {number} action.payload.opacity
     * @param {boolean} action.payload.isActive
     * @param {string} action.payload.title
     * @param {Array<string>} action.payload.description
     */
    registerLayer(state, action) {
      const { payload } = action;
      let indexChecker = _.findIndex(
        state.layerVisibilityList,
        (v) => v.key === payload.key
      );
      if (indexChecker < 0) {
        state.layerVisibilityList.push({
          key: payload.key,
          isActive: payload.isActive,
          opacity: payload.opacity,
          title: payload.title,
          description: payload.description || [],
        });
      }
      return state;
    },
    /**
     * @param {object} action
     * @param {object} action.payload
     * @param {string} action.payload.key
     * @param {number} action.payload.opacity
     * @param {boolean} action.payload.isActive
     * @param {string} action.payload.title
     * @param {Array<string>} action.payload.description
     */
    updateLayer(state, action) {
      const { payload } = action;
      let indexChecker = _.findIndex(
        state.layerVisibilityList,
        (v) => v.key === payload.key
      );
      if (indexChecker > -1) {
        state.layerVisibilityList[indexChecker] = {
          isActive: payload.isActive,
          key: payload.key || state.layerVisibilityList[indexChecker].key,
          title: payload.title || state.layerVisibilityList[indexChecker].title,
          opacity: payload.opacity || 0,
          description:
            payload.description ||
            state.layerVisibilityList[indexChecker].description,
        };
        state.layerVisibilityList = state.layerVisibilityList.map((v) => v);
      }
      return state;
    },
    removeLayer(state, action) {
      let indexChecker = _.findIndex(
        state.layerVisibilityList,
        (v) => v.key === action.payload
      );
      if (indexChecker > -1) {
        state.layerVisibilityList = _.filter(
          state.layerVisibilityList,
          (v) => v.key !== action.payload
        );
      }
      return state;
    },
    clearLayer(state) {
      state.layerVisibilityList = [];
    },
    resetState(state) {
      state = { ...state };
      return state;
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      (action) => {
        return action.type === LEGACY_AUTH_CONSTANT.UNAUTHORIZED;
      },
      () => {
        return { ...initialState };
      }
    ),
});

export const { reducer: MAIN_MAP_REDUCER, actions: MAIN_MAP_ACTION } =
  mainMapSlice;
