import DynamicIcon from '@bvt-icons';
import React from 'react';
import { useNavigate } from 'react-router';
import './HomeLogo.scss';

export default function HomeLogo() {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate({
      pathname: '/app/main/home',
    });
  };

  return (
    <div className='menu-bar__home-logo' onClick={handleBackToHome}>
      <DynamicIcon height='3rem' icon='lilogo' width='7rem' />
    </div>
  );
}
