import { legendActions } from '@bvt-features/pertamina/store/legend/legend.slice';
import { useDispatch, useSelector } from 'react-redux';

export const usePertaminaLegend = () => {
  const state = useSelector((state) => state.features.PERTAMINA.legend);
  const dispatch = useDispatch();

  const resetState = () => dispatch(legendActions.resetState());
  const setItems = (items) => dispatch(legendActions.setItems(items));

  return {
    resetState,
    setItems,
    state,
  };
};
