import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  items: [],
};
export const legendSlice = createSlice({
  name: 'LI/PERTAMINA/LEGEND',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setItems: (state, action) => {
      state.items = action.payload;
    }
  }
});

export const { reducer: legendReducer, actions: legendActions } = legendSlice;
