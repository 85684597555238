/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Col, Divider, Row } from 'antd';
import { Button } from '@bvt-atoms';
import DynamicIcon from '@bvt-icons';
import { useModuleBar } from '../hook';
import ModuleBarMenu from '../component/ModuleBarMenu';
import _ from 'lodash';
import SubMenuList from '../component/SubMenuList';
import EmptySubMenu from '../component/EmptySubMenu';
import { useLocation, useNavigate } from 'react-router';
import { useStep } from '@bvt-features/mapp/step/hook';
import { useProject } from '@bvt-features/mapp/project/hooks/useProject';
import { useMainMapMenuBar } from '@bvt-features/mainmap/menu-bar/hook/useMainMapMenuBar';
import { useSearchParams } from 'react-router-dom';
import SelectedSubMenu from '../component/SelectedSubMenu';
import { useAuth } from '@bvt-features/auth/hooks';
import { usePlace } from '@bvt-features/mainmap/place/hooks/usePlace';
import { PopupCardSelectCountry } from '@bvt-features/mapp/taskAnalyze/component/PopupSelectCountry';
import { generateUUID } from '@bvt-utils';

/**
 * @version 1.0
 * @copyright Bhumi Varta Technology
 */

export default function ModuleBarContainer() {
  const MODULEBAR = useModuleBar();
  const NAVIGATE = useNavigate();
  const STEP = useStep();
  const PLACE = usePlace();
  const PROJECT = useProject();
  const MENUBAR = useMainMapMenuBar();
  const [searchParams] = useSearchParams();
  const LOCATION = useLocation();
  const AUTH = useAuth();

  useEffect(() => {
    const arrOfThisURL = _.filter(LOCATION.pathname.split('/'), (v) => v);
    if (arrOfThisURL.length > 2) {
      const openedMenu = _.filter(
        MODULEBAR.state?.menu_list,
        (v) => v.code === arrOfThisURL[2] && AUTH.hasAccess(v.permission)
      )[0];
      MODULEBAR.setModuleMenuSelected(openedMenu || []);
      MODULEBAR.setModuleSubMenuList(openedMenu?.children || []);
    }
    return () => {
      MODULEBAR.resetState();
    };
  }, []);

  const handleOpenMenu = (e) => {
    const filteredMenu = _.filter(MODULEBAR.state?.menu_list, {
      code: e?.code,
    });
    MODULEBAR.setModuleMenuSelected(filteredMenu[0]);
    MODULEBAR.setModuleSubMenuList(filteredMenu[0].children);
    MODULEBAR.setModuleSubMenuSelected({});
    NAVIGATE(`/app/map/${e?.code}`);
  };

  const handleCloseMenu = () => {
    MODULEBAR.setModuleMenuSelected({});
    MODULEBAR.setModuleSubMenuList([]);
    NAVIGATE(`/app/map/`);
  };

  const handleOpenSubMenu = (e) => {
    if (e?.code === 'asman-telkom') {
      NAVIGATE('/sub-module');
    } else {
      const currentParams = Object.fromEntries([...searchParams]);
      const step = parseInt(currentParams['step']);
      const sURL = `${MODULEBAR.state?.menu_selected?.code}/${e?.code}`;
      MODULEBAR.setModuleSubMenuSelected(e);
      PROJECT.resetState();
      if (step) {
        STEP.setCurrentStep(0);
        STEP.getStepByCode(e?.step);
        STEP.setStepCode(e?.step);
      }
      MENUBAR.resetState();
      NAVIGATE(sURL);
    }
  };

  const handleCloseSubMenu = () => {
    MODULEBAR.setModuleMenuSelected({});
    MODULEBAR.setModuleSubMenuList([]);
    MODULEBAR.setModuleSubMenuSelected({});
    STEP.resetAll();
    NAVIGATE('/app/map');
    const params = {
      meta: {
        filter: { keyword: '' },
        page: 1,
        pageSize: 100,
      },
    };
    PLACE.get_country({ params });
  };

  return (
    <div className='module-bar'>
      <div className='module-bar__menu'>
        <Row>
          <Col className='module-bar__menu__col left' span={6}>
            <Button className='module-bar__menu__button save'>
              <DynamicIcon height='1.25rem' icon='save' width='1.25rem' />
              <div className='module-bar__menu__text'>Save</div>
            </Button>
            <Divider type='vertical' />
          </Col>
          <Col className='module-bar__menu__col right' flex={46}>
            {MODULEBAR.state?.menu_list.map((item) => {
              return (
                AUTH.hasAccess(item.permission) && (
                  <ModuleBarMenu
                    item={item}
                    key={generateUUID()}
                    onClickonOpenMenu={(e) => handleOpenMenu(e)}
                    open={MODULEBAR.state?.menu_selected?.code}
                  />
                )
              );
            })}
          </Col>
        </Row>
      </div>
      <div className='module-bar__submenu'>
        {(() => {
          switch (true) {
            case Object.keys(MODULEBAR?.state?.menu_selected).length > 0 &&
              MODULEBAR?.state?.submenu_list.length > 0 &&
              Object.keys(MODULEBAR?.state?.submenu_selected).length === 0: {
              return (
                <SubMenuList
                  item={MODULEBAR?.state?.submenu_list}
                  onClickCloseMenu={handleCloseMenu}
                  onClickonOpenSubMenu={(e) => handleOpenSubMenu(e)}
                />
              );
            }
            case MODULEBAR?.state?.submenu_list.length === 0: {
              return <EmptySubMenu />;
            }
            case Object.keys(MODULEBAR?.state?.submenu_selected).length > 0: {
              return (
                <SelectedSubMenu
                  item={MODULEBAR?.state?.submenu_selected}
                  onClickCloseSubmenu={() => handleCloseSubMenu()}
                />
              );
            }
            default:
              return null;
          }
        })()}
        {process.env.MODE !== 'production' && <PopupCardSelectCountry />}
      </div>
    </div>
  );
}
