import React, {
  Fragment,
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { usePlace } from '../../hooks/usePlace';
import { useMap } from 'react-leaflet';
import { debounce, isEmpty } from 'lodash';
import MapAdvancedSearch from '../MapAdvancedSearch/MapAdvancedSearch';
import { MapSearchTag } from '../MapSearchTag/MapSearchTag';
import './MapSearch.scss';
import { ID_SOURCE_CONSTANT } from '@bvt-shared/constant/ID_SOURCE_CONSTANT';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { AutoComplete, Input, Select } from 'antd';
import { ImageBox } from '@bvt-shared/component/ImageBox';
import { usePoiSearch } from '@bvt-features/mainmap/poi-search/hooks/usePoiSearch';
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';

const IconSearchTemporary = () => {
  return (
    <svg
      className='search'
      height='1rem'
      viewBox='0 0 15.757 16.145'
      width='1rem'
    >
      <path
        d='M9.288,0A6.9,6.9,0,0,0,3.45,10.585l-.015.006-3,3,2.551,2.551,3.111-3.112,0-.013A6.9,6.9,0,1,0,9.288,0Zm0,3.066A3.835,3.835,0,1,1,5.453,6.9,3.835,3.835,0,0,1,9.288,3.066Z'
        fill='currentColor'
        id='search'
        transform='translate(-0.432)'
      />
    </svg>
  );
};

const idVillage = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.desa) ? LIST_SEARCH.desa.id : '';
};

const idDistrict = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.kecamatan) ? LIST_SEARCH.kecamatan.id : '';
};

const idCity = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.kota) ? LIST_SEARCH.kota.id : '';
};

const idProvince = (LIST_SEARCH) => {
  return !isEmpty(LIST_SEARCH.provinsi) ? LIST_SEARCH.provinsi.id : '';
};

export function MapSearch() {
  const uLocation = useLocation();
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState('');
  const [scroll, setScroll] = useState(false);
  const [advanced, isAdvanced] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [selectType, setSelectType] = useState(!uLocation.pathname.includes('asset-management-pertamina') ? 'admin' : 'poi');
  const [optionsPOI, setOptionsPOI] = useState([]);
  const initialMap = useMap();
  const mapp = useMainMap();
  const inputRef = useRef();

  const PLACE = usePlace();
  const POISEARCH = usePoiSearch();

  const LIST_DATA = PLACE.state.getall.list;
  const LIST_SEARCH = PLACE.state.search.list;
  const LIST_PARAMS = PLACE.state.params.provinsi;
  const STATUS_ADMIN = PLACE.state.getall.status_GET;
  const MSG_ADMIN = PLACE.state.getall.message;
  const STATUS_POI = POISEARCH.status.LIST;
  const LIST_POI = POISEARCH.list;
  const ID_SELECTED = POISEARCH.selectedId;
  const SHOW_ALL = POISEARCH.isShowAll;

  useEffect(() => {
    return () => {
      PLACE.resetAll();
      POISEARCH.removeAll();
      POISEARCH.resetStatusAndMessage();
    };
  }, []);

  useLayoutEffect(() => {
    const mappedData =
      LIST_DATA?.map((item) => ({
        label: item.displayname,
        value: item.displayname,
        type: item.type,
        id: item.id,
      })) || [];
    if (scroll === true) {
      if (!isEmpty(LIST_DATA) && !isEmpty(input)) {
        setOptions([...(options || []), ...mappedData]);
      }
    } else if (scroll === false) {
      setOptions(mappedData);
    }

    if (!isEmpty(MSG_ADMIN)) {
      MSG_ADMIN === 'No records found' && setOptions([]);
    }
  }, [LIST_DATA, scroll]);

  useLayoutEffect(() => {
    const mappedData = LIST_POI?.map((item) => ({
      label: `${item.poi_name} - ${item.address}`,
      value: `${item.poi_name} - ${item.address}`,
      id: item.poi_id,
      key: item.poi_id,
    }));
    setOptionsPOI(mappedData);
  }, [LIST_POI]);

  useEffect(() => {
    if (advanced === true) {
      initialMap.dragging.disable();
      initialMap.doubleClickZoom.disable();
      initialMap.scrollWheelZoom.disable();
    } else {
      initialMap.dragging.enable();
      initialMap.doubleClickZoom.enable();
      initialMap.scrollWheelZoom.enable();
    }
    if (selectType === 'admin' && isEmpty(LIST_PARAMS)) {
      setInput('');
    }
  }, [advanced, LIST_PARAMS]);

  const getListAdmin = (keyword) => {
    const params = {
      meta: { filter: { keyword }, page: page },
    };
    PLACE.get_all({ params });
  };

  const getListPOI = (keyword) => {
    const bbox = initialMap?.getBounds();
    const north_east = bbox?.getNorthEast();
    const south_west = bbox?.getSouthWest();
    const payload = {
      poi_name: keyword,
      north_east,
      south_west,
      province: LIST_SEARCH?.provinsi?.id,
      city: LIST_SEARCH?.kota?.id,
      district: LIST_SEARCH?.kecamatan?.id,
      village: LIST_SEARCH?.desa?.id,
    };
    POISEARCH.getList(payload);
  };

  const resetData = () => {
    PLACE.resetGetAll();
    POISEARCH.removeAll();
    POISEARCH.resetStatusAndMessage();
  };

  const getData = useCallback(
    debounce((value) => {
      if (!value) {
        resetData();
        return;
      }

      if (selectType === 'admin') {
        getListAdmin(value);
      } else {
        getListPOI(value);
      }
    }, 500),
    [selectType]
  );

  const loadMoreResults = () => {
    if (options?.length < PLACE.state.getall.properties.total) {
      const nextPage = page + 1;
      setPage(nextPage);
      const params = {
        meta: { filter: { keyword: input }, page: nextPage },
      };
      PLACE.get_all({ params });
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const handleScroll = (event) => {
    if (selectType === 'admin') {
      const listboxNode = event.currentTarget;
      const position = listboxNode.scrollTop + listboxNode.clientHeight;
      if (listboxNode.scrollHeight - position <= 1) {
        loadMoreResults();
      }
    }
  };

  const handleChangeSelect = (value) => {
    setSelectType(value);
    setInput('');
    PLACE.resetGetAll();
  };

  const onSelectItemList = (payload) => {
    if (selectType === 'admin') {
      PLACE.setGeojson({ provinsi: null });
      PLACE.setGeojson({ kota: null });
      PLACE.setGeojson({ kecamatan: null });
      PLACE.setGeojson({ desa: null });
      const params = {
        meta: { filter: { id: payload.id, type: payload.type } },
      };
      PLACE.get_search({ params });
      setInput(payload.value);
    } else {
      setIsFocus(false);
      inputRef.current.blur();
      POISEARCH.setSelectedId({ id: payload.id });
      POISEARCH.setIsShowAll({ isShowAll: false });
      setInput(payload.value);
    }
  };

  useEffect(() => {
    if (!isEmpty(LIST_SEARCH)) {
      LIST_SEARCH.provinsi !== null
        ? PLACE.setParams({
          type: 'provinsi',
          page: 1,
          id_provinsi: LIST_SEARCH.provinsi.id,
          kode_provinsi: LIST_SEARCH.provinsi.code,
          nama_provinsi: LIST_SEARCH.provinsi.name,
        })
        : void 0;
      LIST_SEARCH.kota !== null
        ? PLACE.setParams({
          type: 'kota',
          page: 1,
          id_kota: LIST_SEARCH.kota.id,
          id_provinsi: LIST_SEARCH.provinsi.id,
          kode_kota: LIST_SEARCH.kota.code,
          nama_kota: LIST_SEARCH.kota.name,
        })
        : void 0;
      LIST_SEARCH.kecamatan !== null
        ? PLACE.setParams({
          type: 'kecamatan',
          page: 1,
          id_kecamatan: LIST_SEARCH.kecamatan.id,
          kode_kecamatan: LIST_SEARCH.kecamatan.code,
          nama_kecamatan: LIST_SEARCH.kecamatan.name,
          id_kota: LIST_SEARCH.kota.id,
        })
        : void 0;
      LIST_SEARCH.desa !== null
        ? PLACE.setParams({
          type: 'desa',
          page: 1,
          id_desa: LIST_SEARCH.desa.id,
          id_kecamatan: LIST_SEARCH.kecamatan.id,
          kode_desa: LIST_SEARCH.desa.code,
          nama_desa: LIST_SEARCH.desa.name,
        })
        : void 0;
      const params = {
        meta: {
          filter: {
            id_province: idProvince(LIST_SEARCH),
            id_city: idCity(LIST_SEARCH),
            id_district: idDistrict(LIST_SEARCH),
            id_village: idVillage(LIST_SEARCH),
            id_source: ID_SOURCE_CONSTANT,
          },
        },
      };
      getAdvanced(params);
    }
  }, [LIST_SEARCH]);

  const getAdvanced = (params) => {
    PLACE.get_advance({ params });
  };

  const onClicSearchkOrEnterPOI = () => {
    if (selectType === 'poi') {
      POISEARCH.setIsShowAll({ isShowAll: true });
      setIsFocus(false);
      inputRef.current.blur();
    }
  };

  const listOptions = selectType === 'admin' ? options : optionsPOI;
  const suffixLoading = <LoadingOutlined />;
  const checkCondition = selectType === 'poi' ? (ID_SELECTED || SHOW_ALL) : !!input;
  const suffix =checkCondition && listOptions?.length > 0 ? (
    <ImageBox
      className='search'
      onClick={() => {
        setInput('');
        resetData();
      }}
      src='ic-close'
    />
  ) : (
    <div
      className='icon-search-wrapper'
      onClick={(e) => {
        e.stopPropagation();
        onClicSearchkOrEnterPOI();
      }}
    >
      <IconSearchTemporary />
    </div>
  );

  const suffixIcon =
    selectType === 'poi' && SHOW_ALL && STATUS_POI === 'LOADING'
      ? suffixLoading
      : suffix;

  const notFoundContent =
    !!input &&
    ((selectType === 'admin' &&
      MSG_ADMIN === 'No records found' &&
      ['SUCCESS', 'FAILED'].includes(STATUS_ADMIN)) ||
    (selectType === 'poi' &&
      LIST_POI?.length === 0 &&
      ['SUCCESS', 'FAILED'].includes(STATUS_POI)) ? (
        <div style={{ color: '#000' }}>No results found</div>
      ) : (
        <div>Loading...</div>
      ));

  return (
    <Fragment>
      {advanced && (
        <MapAdvancedSearch advanced={advanced} isAdvanced={isAdvanced} />
      )}

      <div
        className='bvt-mapsearch'
        onMouseEnter={() => {
          initialMap.dragging.disable();
          initialMap.scrollWheelZoom.disable();
          initialMap.doubleClickZoom.disable();
        }}
        onMouseLeave={() => {
          initialMap.dragging.enable();
          initialMap.scrollWheelZoom.enable();
          initialMap.doubleClickZoom.enable();
        }}
        style={{
          right: `${
            mapp.state.activeMenu ? mapp.state.activeMenuWidth + 'px' : '0.5rem'
          }`,
        }}
      >
        <MapSearchTag />
        <div className='bvt-mapsearch__card'>
          {PLACE.state.country_mandala.country_id === 1 && (
            <Fragment>
              <AutoComplete
                className='bvt-mapsearch__autocomplete'
                dropdownMatchSelectWidth={'18rem'}
                notFoundContent={notFoundContent}
                onBlur={() => setIsFocus(false)}
                onChange={setInput}
                onFocus={() => setIsFocus(true)}
                onPopupScroll={handleScroll}
                onSearch={(value) => {
                  POISEARCH.setIsShowAll({ isShowAll: false });
                  POISEARCH.setSelectedId({ id: null });
                  getData(value);
                }}
                onSelect={(_, option) => {
                  onSelectItemList(option);
                }}
                options={input && listOptions}
                placeholder={!uLocation.pathname.includes('asset-management-pertamina') ? 'Search' : ' POI Search'}
                style={{
                  width: isFocus ? '18rem' : '16rem',
                  transition: 'width ease-in-out 0.5s',
                }}
                value={input}
              >
                <Input
                  onFocus={() => setIsFocus(true)}
                  onPressEnter={onClicSearchkOrEnterPOI}
                  ref={inputRef}
                  suffix={suffixIcon}
                />
              </AutoComplete>
            </Fragment>
          )}
          {selectType === 'admin' &&
            !uLocation.pathname.includes('asset-management-pertamina') && (
            <button
              className='bvt-mapsearch__button'
              disabled={PLACE.state.isAdvanceSearchDisabled}
              onClick={() => isAdvanced(!advanced)}
            >
                Advanced
            </button>
          )}
          {!uLocation.pathname.includes('asset-management-pertamina') && (
            <Select
              className={`bvt-mapsearch__select ${
                selectType === 'poi' ? 'search-poi' : ''
              }`}
              onBlur={() => setIsOpenSelect(false)}
              onChange={handleChangeSelect}
              onClick={() => setIsOpenSelect(!isOpenSelect)}
              open={isOpenSelect}
              options={[
                { value: 'admin', label: 'Admin' },
                { value: 'poi', label: 'POI' },
              ]}
              value={selectType}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
}
