import React from 'react';
import { PertaminaLegend } from '@bvt-features/pertamina/components/Legend';
import { useMap } from 'react-leaflet';
import { useMainMap } from '@bvt-features/mainmap/mainmap/hook/useMainMap';
import { usePertaminaLegend } from '@bvt-features/pertamina/hooks/usePertaminaLegend';

export const PertaminaLegendContainer = () => {
  const initialMap = useMap();
  const mainMap = useMainMap();
  const legend = usePertaminaLegend();

  if (legend.state.items.length === 0) {
    return null;
  }

  return (
    <PertaminaLegend
      items={legend.state.items}
      onMouseEnter={() => {
        initialMap.scrollWheelZoom.disable();
        initialMap.doubleClickZoom.disable();
        initialMap.dragging.disable();
      }}
      onMouseLeave={() => {
        initialMap.scrollWheelZoom.enable();
        initialMap.doubleClickZoom.enable();
        initialMap.dragging.enable();
      }}
      style={{
        right: mainMap.state.activeMenu
          ? mainMap.state.activeMenuWidth + 8 + 'px'
          : '1rem',
      }}
    />
  );
};
