import { createAsyncThunk } from '@reduxjs/toolkit';
import { PertaminaService } from '@bvt-features/pertamina/service/pertaminService';
import { STORE_PREFIX } from '@bvt-features/pertamina/constant/STORE_PREFIX';
import { assetActions } from './asset.slice';

const pertaminaService = new PertaminaService();

export const getListAsset = createAsyncThunk(
  `${STORE_PREFIX}/getListAsset`,
  async (param, { rejectWithValue }) => {
    try {
      const resp = await pertaminaService.getListAsset(param);
      return resp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDetailAsset = createAsyncThunk(
  `${STORE_PREFIX}/getDetailAsset`,
  async (param, { rejectWithValue }) => {
    try {
      const resp = await pertaminaService.getDetailAsset(param);
      return resp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doDownloadAssetList = createAsyncThunk(
  `${STORE_PREFIX}/doDownloadAssetList`,
  async (param, { rejectWithValue, dispatch }) => {
    try {
      const resp = await pertaminaService.downloadAssetList(
        param,
        (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          dispatch(assetActions.setDownloadTotalSize(progressEvent.total));
          dispatch(assetActions.setDownloadLoadedSize(progressEvent.loaded));
          dispatch(assetActions.setDownloadProgress(progress));
        }
      );
      const base64Data = resp.file;
      const uint8Array = new Uint8Array(
        atob(base64Data)
          .split('')
          .map((char) => char.charCodeAt(0))
      );
      const blob = new Blob([uint8Array], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const filename = resp.filename;
      return { url, filename };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUpdateAsset = createAsyncThunk(
  `${STORE_PREFIX}/createUpdateAsset`,
  async (param, { rejectWithValue }) => {
    try {
      return await pertaminaService.createUpdateAsset(param);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * @param {string} param
 */
export const doDeleteAsset = createAsyncThunk(
  `${STORE_PREFIX}/doDeleteAsset`,
  async (param, { rejectWithValue }) => {
    try {
      const resp = await pertaminaService.deleteAsset(param);
      return resp;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
