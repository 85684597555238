import { combineReducers } from 'redux';
import { assetReducer } from './asset/asset.slice';
import { legendReducer } from './legend/legend.slice';
import { droppableMarkerReducer } from './droppable-marker/droppable_marker.slice';

export const PERTAMINA_REDUCER = combineReducers({
  asset: assetReducer,
  legend: legendReducer,
  droppableMarker: droppableMarkerReducer,
});
