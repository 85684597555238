const dataMenu = [
  {
    id: 1,
    name: 'Map Analytic',
    code: 'map-analytic',
    icon: 'mapAnalytic',
    status: true,
    permission: 'MAPANA',
    children: [
      {
        id: 0,
        name: 'Site Profiling',
        code: 'site-profiling',
        icon: 'siteProfiling',
        step: 'SITE_PROFILING',
        active: true,
        permission: 'MAPANA.SIPRO',
      },
      {
        id: 1,
        name: 'Grid Analysis',
        code: 'grid-analysis',
        step: 'GRID_ANALYTIC',
        icon: 'gridAnalysis',
        active: true,
        permission: 'MAPANA.GRIANA',
      },
      {
        id: 3,
        name: 'Site Profiling Mandala',
        code: 'site-profiling-mandala',
        icon: 'siteProfiling',
        step: 'SITE_PROFILING',
        active: true,
        permission: 'RND.SIPRO-MANDALA',
      },
      {
        id: 4,
        name: 'Grid Analysis Lawu',
        code: 'grid-analysis-lawu',
        step: 'GRID_ANALYTIC',
        icon: 'gridAnalysis',
        active: true,
        permission: 'RND.GRIANA-LAWU',
      },
      {
        id: 2,
        name: 'Asset Management Telkom Property',
        code: 'asman-telkom',
        step: 'ASMAN_TELKOM',
        icon: 'assetManagement',
        active: true,
        permission: 'MAPANA.ASMANTELKOM',
      },
      {
        id: 4,
        name: 'NFR Business Analysis',
        code: 'asset-management-pertamina',
        step: 'ASMAN_PERTAMINA',
        icon: 'pertaminaIcon',
        active: true,
        permission: 'MAPANA.ASMANPERTAMINA',
      },
    ],
  },
];

export default dataMenu;
