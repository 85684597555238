import React, { Fragment, useMemo, useRef } from 'react';
import L from 'leaflet';
import * as Leaflet from 'react-leaflet';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import * as BvtIcon from 'bvt-icon';
import { Spin } from 'antd';
import IconTelkom from '@bvt-assets/images/telkom-property.png';
import IconPertamina from '@bvt-features/pertamina/assets/new-marker.png';

/**
 * @version 1.0
 * @copyright PT Bhumi Varta Technology
 * @memberof map
 * @author Imam <imam.rozali@bvarta.com>
 * @author Ami <amiruriza@bvarta.com>
 * @param {Object} props
 * @param {Array} props.position Lat Long
 * @param {String} props.iconName name Sub Category
 * @param {JSX.Element} props.children popup content
 * @param {boolean} props.loading marker loader
 */

export default function Marker(props) {
  const {
    children,
    colorIcon,
    draggable,
    iconName,
    position,
    setPosition,
    loading,
  } = props;
  const markerRef = useRef(null);

  const Icon =
    // eslint-disable-next-line import/namespace
    BvtIcon[iconName] !== undefined ? BvtIcon[iconName] : BvtIcon.DefaultPoi;

  const iconMarker = iconName === 'custom-poi'
    ? <img src={IconTelkom} style={{ width: '2.5rem', height: '2.5rem' }} />
    : <Icon color={colorIcon} height='2.5rem' width='2.5rem' />;

  const ImageUrl = new L.DivIcon({
    html: renderToStaticMarkup(
      loading ? <Spin /> : iconMarker
    ),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [8, -34],
    shadowSize: [41, 41],
  });

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker !== null) {
          setPosition && setPosition(marker.getLatLng());
        }
      },
    }),
    []
  );

  const markerLoading = new L.DivIcon({
    html: renderToStaticMarkup(<Spin />),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [8, -34],
    shadowSize: [41, 41],
  });

  const markerPertamina = L.icon({
    iconUrl: IconPertamina,
    iconSize: [36, 42],
    iconAnchor: [18, 40],
  });

  const iconPertamina = loading ? markerLoading : markerPertamina;

  return (
    <Fragment>
      {position && (
        <Leaflet.Marker
          draggable={draggable}
          eventHandlers={eventHandlers}
          icon={iconName === 'pertamina' ? iconPertamina : ImageUrl}
          position={position}
          ref={markerRef}
        >
          {!!children && (
            <Leaflet.Popup closeButton={false}>{children}</Leaflet.Popup>
          )}
        </Leaflet.Marker>
      )}
    </Fragment>
  );
}

Marker.defaultProps = {
  children: null,
  colorIcon: 'currentColor',
  draggable: false,
  iconName: 'DefaultPoi',
  position: null,
};

Marker.propTypes = {
  children: PropTypes.node,
  colorIcon: PropTypes.string,
  draggable: PropTypes.bool,
  iconName: PropTypes.string,
  position: PropTypes.array,
  setPosition: PropTypes.func,
};
